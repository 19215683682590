import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "react-bootstrap";
import { companyData } from "../../redux/actions/company.actions";
import { extractAndFormatURLPart } from "../../constants/breadCrumb";
import { useNavigate } from "react-router-dom";
import "./dashboard.css"

const Dashboard = () => {
  const URL = extractAndFormatURLPart("");
  const navigate = useNavigate();
  //state
  const [loading, setLoading] = useState(false);
  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname } =
    useSelector((state) => state.authManager.userData.data[0]) || {};

  //default data backend needed
  const companybackendData = {
    companyid,
    userid,
    companyname,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(companyData(companybackendData, setLoading));
  }, [dispatch, loadData]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="welcome-page">
              {/* <h1>Saanvi Enterprises</h1> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
