import React, { useState } from "react";
import { AnimatePresence, calcLength, motion } from "framer-motion";
import { FaBars } from "react-icons/fa";
import SidebarMenu from "./SidebarMenu";
import { NavLink } from "react-router-dom";
import { routes1 } from "./Route";
import "../../styles/Sidebar/Sidebar.css";
import Saanvi_enterprises from "../../assets/venturesathi-logo.png";
import vs_logo from "../../assets/venturesathi-logo.png";
import Header from "../Header/Header";
import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { module, submodule,companyid } = useSelector((state) => state && state.authManager && state.authManager.userData && state.authManager.userData.data && state.authManager.userData.data[0] ) || {};

  // const { companyid, userid } = useSelector((state) => state.authManager.userData.data[0]) || {};

  const filterRoutes = (routes, module, submodule) => {
    return routes
      .map((route) => {
        if (module && module.includes(route.name)) {
          if (submodule && route.subRoutes) {
            const filteredSubRoutes = route.subRoutes.filter((subRoute) =>
              submodule.includes(subRoute.name)
            );

            return { ...route, subRoutes: filteredSubRoutes };
          }
          return route;
        } else {
          return null;
        }
      })
      .filter((route) => route !== null);
  };

  const filteredRoutes = filterRoutes(routes1, module, submodule);
  const toggle = () => setIsOpen(!isOpen);
  let routesToRender;

  routesToRender = filteredRoutes;

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "250px" : "50px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section" onClick={toggle}>
            <AnimatePresence>
              {isOpen && (
                <motion.img
                  src={companyid=="vs"?vs_logo:Saanvi_enterprises}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                />
              )}
            </AnimatePresence>

            {!isOpen && (
              <div className="bars">
                <FaBars onClick={toggle} />
              </div>
            )}
          </div>
          <motion.section
            className="routes"
            animate={{
              width: isOpen ? "250px" : "50px",
              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
          >
            {routesToRender.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              }
              return (
                <NavLink to={route.path} key={index} className="link">
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </motion.section>
        </motion.div>

        <motion.main
          initial={{ marginLeft: isOpen ? "25.5rem" : "5.5rem" }}
          animate={{ marginLeft: isOpen ? "25.5rem" : "5.5rem" }}
          transition={{ duration: 0.5, type: "spring", damping: 10 }}
        >
          <Header isOpen={isOpen} />
          <div className="main-children">{children}</div>
        </motion.main>
      </div>
    </>
  );
};

export default Sidebar;
